import React, {useEffect, useState} from 'react';
import "./AllEventsSingleEvent.scss";
import EventsApi from "../../../../api/EventsApi";
import {getAlbumPath} from "../../../../tools/formatter";
import DiaryWrapper from "../../DiaryWrapper";
import ReactImageGallery from "../../../../components/ReactImageGallery/ReactImageGallery";
import {connect} from "react-redux";

function AllEventsSingleEvent(props) {
    const [currentEvent, setCurrentEvent] = useState([]);
    let content = "Single Event";

    useEffect(() => {
        console.log('componentdidmount single event');
        if ( props.userId && props.currentNavigationEventId) {
            EventsApi.getUserEvent(props.userId, props.currentNavigationEventId).then(response => {
                setCurrentEvent(response.data.event);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationEventId]);

    if (currentEvent) {
        content = <div>
            <div className={"album-block"} >
                <ReactImageGallery path={getAlbumPath(currentEvent)} />
            </div>
        </div>;
    }

    return <DiaryWrapper className={'EventsByDay'} headerText={currentEvent.title}>{content}</DiaryWrapper>
}

const mapStateToProps = state => {
    return {
        currentNavigationEventId: state.eventsData.currentNavigationEventId
    };
};

export default connect(mapStateToProps)(AllEventsSingleEvent);
