import React, {useEffect, useState} from 'react';
import "./AllEventsYears.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import YearItem from "../../EventsByYear/YearItem/YearItem";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";

function AllEventsYears(props) {
    const [years, setYears] = useState([]);

    const setCurrentNavigationYear = (navigationYear) => {
        props.onUpdateCurrentNavigationYear(navigationYear);
    }


    useEffect(() => {
        if ( props.userId ) {
            AllEventsApi.getByYears(props.userId).then(response => {
                console.log(response.data);
                setYears(response.data.rows);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId]);


    return <DiaryWrapper headerText={'Album'}>{years.map(yearInfo => {
        return (
            <div key={yearInfo.eventsYear}  onClick = {() => {
                setCurrentNavigationYear(yearInfo.eventsYear);
            }}>
                <YearItem item={yearInfo} />
            </div>
        )
    })}</DiaryWrapper>;
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationYear: (currentNavigationYear) => dispatch(actionCreators.updateNavigationYear(currentNavigationYear))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsYears);
