export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const GET_DATE = 'GET_DATE';
export const GET_IMAGES_STATS = 'GET_IMAGES_STATS';
export const UPDATE_NAVIGATION_YEAR = 'UPDATE_NAVIGATION_YEAR';
export const UPDATE_NAVIGATION_MONTH = 'UPDATE_NAVIGATION_MONTH';
export const UPDATE_NAVIGATION_DAY = 'UPDATE_NAVIGATION_DAY';
export const UPDATE_NAVIGATION_EVENT_ID = 'UPDATE_EVENT_ID';

export const getEvents = (events) => {
    return {
        type: GET_EVENTS,
        events: events
    };
};

export const getEvent = (currentEvent) => {
    return {
        type: GET_EVENT,
        currentEvent: currentEvent
    };
};

export const updateEvent = (currentEvent) => {
    return {
        type: UPDATE_EVENT,
        currentEvent: currentEvent
    };
};

export const addEvent = (newEvent) => {
    return {
        type: ADD_EVENT,
        newEvent: newEvent
    };
};

export const deleteEvent = (eventId) => {
    return {
        type: DELETE_EVENT,
        eventId: eventId
    };
};

export const getDate = (currentDate) => {
    return {
        type: GET_DATE,
        currentDate: currentDate
    };
};

export const getImagesStats = (currentImagesStats) => {
    return {
        type: GET_IMAGES_STATS,
        currentImagesStats: currentImagesStats
    };
};

export const updateNavigationYear = (navigationYear) => {
    return {
        type: UPDATE_NAVIGATION_YEAR,
        currentNavigationYear: navigationYear
    };
};

export const updateNavigationMonth = (navigationMonth) => {
    return {
        type: UPDATE_NAVIGATION_MONTH,
        currentNavigationMonth: navigationMonth
    };
};

export const updateNavigationDay = (navigationDay) => {
    return {
        type: UPDATE_NAVIGATION_DAY,
        currentNavigationDay: navigationDay
    };
};

export const updateNavigationEventId = (navigationEventId) => {
    return {
        type: UPDATE_NAVIGATION_EVENT_ID,
        currentNavigationEventId: navigationEventId
    };
};