import React, {useEffect, useState} from 'react';
import "./AllEventsMonths.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import MonthItem from "../../EventsByMonth/MonthItem/MonthItem";

function AllEventsMonths(props) {
    const [months, setMonths] = useState([]);

    const setCurrentNavigationMonth = (navigationMonth) => {
        props.onUpdateCurrentNavigationMonth(navigationMonth);
    }


    useEffect(() => {
        if ( props.userId && props.currentNavigationYear ) {
            AllEventsApi.getByMonths(props.userId, props.currentNavigationYear).then(response => {
                let allMonths = new Array(13).fill(null);
                response.data.rows.forEach(month => {
                    allMonths[month.eventsMonth] = month;
                });
                setMonths(allMonths);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationYear]);


    return <DiaryWrapper headerText={'Months'}>{months.map((monthInfo, index) => {
        return (
            index > 0 ?
                <div key={index}  onClick = {() => {
                    setCurrentNavigationMonth(index);
                }}>
                    <MonthItem item={monthInfo}  monthNumber={index} />
                </div> : ''
        )
    })}</DiaryWrapper>;
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear,
        currentNavigationMonth: state.eventsData.currentNavigationMonth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationMonth: (currentNavigationMonth) => dispatch(actionCreators.updateNavigationMonth(currentNavigationMonth))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsMonths);
