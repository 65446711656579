import React, {useEffect, useState} from 'react';
import "./AllEventsForDay.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import MonthItem from "../../EventsByMonth/MonthItem/MonthItem";
import {DAY_FULL, FULL_DATE, getCalendarMonth} from "../../../../tools/formatter";
import moment from "moment";
import {Link} from "react-router-dom";
import DayItem from "../../EventsByDay/DayItem/DayItem";
import EventItem from "../../EventsForDay/EventItem/EventItem";

function AllEventsForDay(props) {
    const [dayEvents, setDayEvents] = useState([]);

    const setCurrentNavigationEventId = (eventId) => {
        props.onUpdateCurrentNavigationEventId(eventId);
    }


    useEffect(() => {
        if ( props.userId && props.currentNavigationYear && props.currentNavigationMonth && props.currentNavigationDay ) {
            const year = props.currentNavigationYear;
            const month = props.currentNavigationMonth;
            const day = props.currentNavigationDay;
            AllEventsApi.getDay(props.userId, year, month, day).then(response => {
                console.log(response.data);
                setDayEvents(response.data.rows);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationYear, props.currentNavigationMonth, props.currentNavigationDay]);

    return <DiaryWrapper headerText={'Events'}>{dayEvents.map((dayInfo) => {
        return (
            <div key={dayInfo.eventPath}  onClick = {() => {
                setCurrentNavigationEventId(dayInfo.eventPath);
            }}>
                <EventItem item={dayInfo} />
            </div>
        )
    })}</DiaryWrapper>;
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear,
        currentNavigationMonth: state.eventsData.currentNavigationMonth,
        currentNavigationDay: state.eventsData.currentNavigationDay,
        currentNavigationEventId: state.eventsData.currentNavigationEventId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationEventId: (currentEventId) => dispatch(actionCreators.updateNavigationEventId(currentEventId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsForDay);
