import React, {useEffect, useState} from 'react';
import "./AllEvents.scss";
import {useParams} from "react-router-dom";
import AllEventsYears from "./AllEventsYears/AllEventsYears";
import AllEventsMonths from "./AllEventsMonths/AllEventsMonths";
import AllEventsDays from "./AllEventsDays/AllEventsDays";
import AllEventsForDay from "./AllEventsForDay/AllEventsForDay";
import AllEventsSingleEvent from "./AllEventsSingleEvent/AllEventsSingleEvent";

function AllEvents(props) {
    const { userId } = useParams();

    return <div>
        <AllEventsYears userId={userId} />
        <AllEventsMonths userId={userId} />
        <AllEventsDays userId={userId} />
        <AllEventsForDay userId={userId} />
        <AllEventsSingleEvent userId={userId} />
    </div>
}

export default AllEvents;
