import * as actionTypes from '../actions/actions';

const initialState = {
    events: [],
    currentEvent: null,
    imagesStats: null,
    currentNavigationYear: null,
    currentNavigationMonth: null,
    currentNavigationDay: null,
    currentNavigationEventId: null
};

const eventsReducer = (state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_EVENTS:
            return {
                ...state,
                events: action.events
            }
        case actionTypes.GET_EVENT:
            return {
                ...state,
                currentEvent: action.currentEvent
            }
        case actionTypes.GET_IMAGES_STATS:
            return {
                ...state,
                imagesStats: action.currentImagesStats
            }
        // TODO Delete without refresh
        case actionTypes.DELETE_EVENT:
            return {
                ...state
            }
        case actionTypes.UPDATE_NAVIGATION_YEAR:
            return {
                ...state,
                currentNavigationYear: action.currentNavigationYear,
                currentNavigationMonth: null,
                currentNavigationDay: null,
                currentNavigationEventId: null,
                currentEvent: null
            }
        case actionTypes.UPDATE_NAVIGATION_MONTH:
            return {
                ...state,
                currentNavigationMonth: action.currentNavigationMonth,
                currentNavigationDay: null,
                currentNavigationEventId: null,
                currentEvent: null
            }
        case actionTypes.UPDATE_NAVIGATION_DAY:
            return {
                ...state,
                currentNavigationDay: action.currentNavigationDay,
                currentNavigationEventId: null,
                currentEvent: null
            }
        case actionTypes.UPDATE_NAVIGATION_EVENT_ID:
            return {
                ...state,
                currentNavigationEventId: action.currentNavigationEventId,
                currentEvent: null
            }
        default:
            return {
                ...state
            }
    }
};

export default eventsReducer;