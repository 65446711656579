const awsconfig = {
    "API": {
        "endpoints": [
            {
                "name": "Impressions",
                "endpoint": "https://4eoh1izh32.execute-api.us-east-1.amazonaws.com/beta"
            },
            {
                "name": "eventsapi",
                "endpoint": "https://qwm3o6kq72.execute-api.us-east-1.amazonaws.com/dev"
            },
            {
                "name": "eventsapi",
                "endpoint": "https://qwm3o6kq72.execute-api.us-east-1.amazonaws.com/dev"
            }
        ]
    }
}
export default awsconfig;