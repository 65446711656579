import React from 'react';
import './YearItem.scss';
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";

function YearItem(props) {
	const {item} = props;
	return <div className={'YearItem DiaryItem'}>
		<div className={"Header" + (props.currentNavigationYear == item.eventsYear ? " active" : "")}>{item.eventsYear}</div>
		<div className={'EventsCount'}>&#128450;&nbsp;{item.eventsCount}</div>
		<div className={'ImagesCount'}>&#128444;&nbsp;{item.imagesCount}</div>
	</div>;
}

const mapStateToProps = state => {
	return {
		currentNavigationYear: state.eventsData.currentNavigationYear
	};
};

export default connect(mapStateToProps)(YearItem);
